import styled, {
  createGlobalStyle,
} from 'styled-components/macro';
import React, {
  useRef,
  useEffect,
} from 'react';
import SelectAntd from 'antd/es/select';
import 'antd/es/select/style/css';
import {
  theme,
} from '../utilsClient/cssHelpers';
import {
  resetStyles,
  lineHeights,
  sizes,
  fontSizes,
  transitionStyles,
  activeStyles,
} from './styles/form-controls';

const ComponentContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

const Component = styled(SelectAntd)`
  &&& {
    &,
    .ant-select-arrow {
      color: ${theme('color.onSurface')};
    }

    &:not(.ant-select-multiple)
      .ant-select-selector
      .ant-select-selection-item {
      ${props => lineHeights[props.size || 'default']};
    }
    &.ant-select-multiple .ant-select-selector .ant-select-selection-item {
      border-radius: ${theme('border.2')};
      /* TODO: Should be in sync with theme */
      border: none;
    }

    .ant-select-selector {
      ${resetStyles};

      background-color: ${theme('color.surface')};
      border-radius: ${theme('border.radius.2')};
      box-shadow: ${theme('boxShadow.base')};
      padding-right: ${theme('space.2')};
      padding-left: ${theme('space.2')};

      ${transitionStyles};

      /* Modifiers */
      ${props => sizes[props.size || 'default']};
    }

    .ant-select-selection-item {
      ${props => fontSizes[props.size || 'default']};
    }

    /* Makes select menu clear button always visible */
    .ant-select-clear {
      opacity: 1;
    }

    &.ant-select-disabled {
      .ant-select-selector {
        background-color: ${theme('color.disabled')};
      }

      .ant-select-selector,
      .ant-select-arrow {
        color: ${theme('color.onDisabled')};
      }
    }

    &:hover,
    &.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        ${activeStyles};
      }
    }
  }
`;

const GlobalStyle = createGlobalStyle`
  .ant-select-dropdown {
    &&& {
      background-color: ${theme('color.surface')};
    }
  }

  .ant-select-item-option {
    &&& {
      color: ${theme('color.onSurface')};

      &-selected {
        color: ${theme('color.onSurface')};
      }

      &-selected,
      &-active,
      &:hover:not(.ant-select-item-option-disabled) {
        background-color: ${theme('color.hover')};
      }
    }
  }

  .ant-empty {
    && {
      color: ${theme('color.onSurface')};
    }
  }
`;

const StyledSelect = ({
  // eslint-disable-next-line react/prop-types
  showSearch,
  ...rest
}) => {
  const ref = useRef(null);

  useEffect(() => {
    // Ant Design select comes with autocomplete="off" for the search input
    // but it doesn't seem to work in Chrome
    // adding a dummy value to autocomplete attr seems to fix the issue
    // as per https://stackoverflow.com/a/38961567
    if (showSearch) {
      const searchInput = ref.current.querySelector(
        '.ant-select-selection-search-input',
      );

      if (searchInput) {
        searchInput.setAttribute('autocomplete', 'nope');
      }
    }
  }, [
    showSearch,
  ]);

  return (
    <ComponentContainer ref={ref}>
      <GlobalStyle />
      <Component
        showSearch={showSearch}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    </ComponentContainer>
  );
};

export default SelectAntd;

export {
  StyledSelect,
};
