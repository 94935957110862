import i18next from 'i18next';
import messageBus from './utils/messageBus';
import notification from './common/utilsClient/notification';
import logger from './common/logger';

messageBus.on(
  'apolloError',
  ({
    errorType,
    message,
    messageKey,
    locations,
    extensions,
    path,
  }) => {
    switch (errorType) {
      case 'tokenError': {
        notification.error({
          key: 'tokenError',
          message: message || i18next.t(messageKey),
          duration: 0,
        });
        break;
      }
      default:
      // ...
    }
    logger.error(`[${errorType}]: ${message || messageKey}`, {
      stack:
        extensions &&
        extensions.exception &&
        extensions.exception.stacktrace &&
        extensions.exception.stacktrace.join('\n'),
      meta: {
        locations,
        path,
        extensions,
      },
    });
  },
);
