import {
  css,
} from 'styled-components';
import theming from 'styled-theming';
import {
  theme,
} from '../../../utilsClient/cssHelpers';

const clippedStyles = css`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const getAbsoluteFontSize = (props) => {
  switch (props.size) {
    case 'xs':
      return theme('font.size.xs')(props);
    case 'small':
      return theme('font.size.small')(props);
    case 'large':
      return theme('font.size.large')(props);
    default:
      return null;
  }
};

const getRelativeFontSize = (props) => {
  switch (props.size) {
    case 'xs':
      return theme('font.size.xs')(props);
    case 'small':
      return theme('font.size.smaller')(props);
    case 'large':
      return theme('font.size.larger')(props);
    default:
      return null;
  }
};

const stylesImportanceHigh = theming('mode', {
  light: css`
    font-weight: ${theme('font.weight.bold')};
  `,
  dark: css`
    font-weight: ${theme('font.weight.bold')};
    color: rgba(255, 255, 255, 1);
  `,
});

const stylesImportanceLow = theming('mode', {
  light: css`
    color: rgba(0, 0, 0, 0.65);
  `,
  dark: css`
    color: rgba(255, 255, 255, 0.65);
  `,
});

const getImportance = (props) => {
  switch (props.importance) {
    case 'high':
      return stylesImportanceHigh;
    case 'low':
      return stylesImportanceLow;
    default:
      return null;
  }
};

const getTransform = (props) => {
  switch (props.transform) {
    case 'uppercase':
      return `
        text-transform: uppercase;
        letter-spacing: 0.05em;
      `;
    default:
      return null;
  }
};

const getDecoration = (props) => {
  switch (props.decoration) {
    case 'underline':
      return `
        text-decoration: underline;
      `;
    default:
      return null;
  }
};

const getWeight = (props) => {
  switch (props.weight) {
    case 'normal':
      return theme('font.weight.normal');
    default:
      return null;
  }
};

const getType = (props) => {
  switch (props.type) {
    case 'default':
      return 'inherit';
    case 'primary':
      return theme('color.primary')(props);
    case 'danger':
      return theme('color.danger')(props);
    case 'warning':
      return theme('color.warning')(props);
    case 'success':
      return theme('color.success')(props);
    default:
      return null;
  }
};

export const getToken = (props) => {
  const {
    token,
  } = props;

  if (token) {
    return theme(`typography.${token}`);
  }

  return null;
};

const getLength = (props) => {
  const {
    length,
  } = props;

  if (length) {
    return theme(`measure.${length}`);
  }

  return null;
};

const styles = css`
  ${props => getImportance(props)};
  ${props => getTransform(props)};
  ${props => getDecoration(props)};
  ${props => getToken(props)};
  ${props => props.isClipped && clippedStyles};

  max-width: ${props => getLength(props)};
  font-weight: ${props => getWeight(props)};
  color: ${props => getType(props)};
  text-align: ${props => props.align};

  strong {
    ${stylesImportanceHigh}
  }

  > svg {
    height: 1em;
    width: 1em;
  }
`;

const absoluteStyles = css`
  ${styles};

  font-size: ${props => getAbsoluteFontSize(props)};
`;

const relativeStyles = css`
  ${styles};

  font-size: ${props => getRelativeFontSize(props)};
`;

const colorStyles = css`
  /* ${props => `color: hsla(${theme('color.darkBase')(props)}, 0.8)`}; */
`;

export {
  absoluteStyles,
  relativeStyles,
  colorStyles,
};
