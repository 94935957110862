import {
  css,
} from 'styled-components';
import {
  theme,
} from '../../utilsClient/cssHelpers';

export const OUTLINE_SIZE = '2px';

export const fontSizes = {
  small: css`
    font-size: ${theme('font.size.small')};
  `,
  default: css`
    font-size: ${theme('font.size.base')};
  `,
  large: css`
    font-size: ${theme('font.size.large')};
  `,
};

export const lineHeights = {
  small: css`
    line-height: ${theme('size.small')};
  `,
  default: css`
    line-height: ${theme('size.base')};
  `,
  large: css`
    line-height: ${theme('size.large')};
  `,
};

export const sizes = {
  small: css`
    height: ${theme('size.small')};
  `,
  default: css`
    height: ${theme('size.base')};
  `,
  large: css`
    height: ${theme('size.large')};
  `,
};

export const notAllowedStyles = css`
  cursor: not-allowed;
`;

const disabledStyles = css`
  ${notAllowedStyles};

  background-color: ${theme('color.disabled')};
  color: ${theme('color.onDisabled')};
`;

export const transitionStyles = css`
  transition: outline ${theme('motion.duration.fast')}
    ${theme('motion.easing.standard')};
`;

export const activeStyles = css`
  box-shadow: 0 0 0 ${OUTLINE_SIZE} ${theme('color.focus')};
`;

export const resetStyles = css`
  background: none;
  border: none;
  outline: none;
`;

export default css`
  ${resetStyles};

  padding: ${theme('space.1')} ${theme('space.2')};
  border-radius: ${theme('border.radius.2')};
  box-shadow: ${theme('boxShadow.base')};
  background-color: ${theme('color.surface')};
  color: ${theme('color.onSurface')};
  width: 100%;

  ${transitionStyles}

  &::placeholder {
    color: ${theme('color.placeholder')};
  }

  /* Pseudo */

  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    ${props => !props.disabled && activeStyles};
  }

  /* Modifiers */

  ${props => props.size && sizes[props.size]};
  ${props => props.disabled && disabledStyles};
`;
