/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components/macro';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useTranslation,
} from 'react-i18next';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  Settings as SettingsIcon,
} from 'styled-icons/material';
import preferences from '../store/preferences';
import Stack from '../common/components/primitives/Stack';
import Cluster from '../common/components/primitives/Cluster';
import Text from '../common/components/base/Text';
import Modal from '../common/components/Modal/Modal';
import Skeleton from '../common/components/Skeleton';
import Button from '../common/components/Button';
import DarkModeToggle from '../common/components/DarkModeToggle';
import {
  useTheme,
} from '../common/components/ThemeProvider';
import usePrefersReducedMotion from '../common/utils/usePrefersReducedMotion';

const selectFontSize = preferences.get('fontSize');
const selectAnimationSpeed = preferences.get('animationSpeed');

const StyledWrapper = styled(({
  onTop,
  ...rest
}) => <div {...rest} />)`
  ${props => props.onTop &&
    `
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  `};
`;

const DarkModeToggleContainer = () => {
  const theme = useTheme();
  const checked = theme.mode === 'dark';
  const onToggle = bool => theme.toggle(bool);

  return (
    <DarkModeToggle
      checked={checked}
      onToggle={onToggle}
    />
  );
};

const Settings = ({
  loading,
  onTop,
}) => {
  const {
    t,
  } = useTranslation();
  const [
    isVisible,
    setIsVisible,
  ] = useState(false);
  const dispatch = useDispatch();
  const fontSize = useSelector(selectFontSize);
  const animationSpeed = useSelector(selectAnimationSpeed);
  const prefersReducedMotion = usePrefersReducedMotion();
  const onChange = (preference, value) => dispatch(preferences.set(preference, value));

  const fontSizeOptions = [
    {
      key: 1,
      name: t('small'),
      value: 14,
    },
    {
      key: 2,
      name: t('medium'),
      value: 16,
    },
    {
      key: 3,
      name: t('large'),
      value: 18,
    },
  ];

  const animationSpeedOptions = [
    {
      key: 'disabled',
      name: t('disabled'),
      value: 0,
    },
    {
      key: 'normal',
      name: t('normal'),
      value: 400,
      disabled: prefersReducedMotion,
    },
  ];
  const getAnimationSpeedOption = key => animationSpeedOptions.find(option => option.key === key) || {};

  // TODO: I disabled eslint rule that says to add more dependencies to useEffect array though I don't need it
  // e.g. 'onChange' and 'getAnimationSpeedOption'
  // I may be wrong disabling it so let's see how it works out
  useEffect(() => {
    if (prefersReducedMotion) {
      onChange('animationSpeed', getAnimationSpeedOption('disabled').value);
    } else if (!isNull(prefersReducedMotion)) {
      // It allows to automatically switch animtion speed by enabling / disabling 'reduce motion' OS setting
      onChange('animationSpeed', getAnimationSpeedOption('normal').value);
    }
    // eslint-disable-next-line
  }, [prefersReducedMotion]);

  useEffect(() => {
    document.documentElement.style.fontSize = `${(fontSize * 100) / 16}%`;
  }, [
    fontSize,
  ]);

  return (
    <StyledWrapper
      onTop={onTop}
      data-testid="button-menu"
    >
      <Button
        type="link"
        onClick={() => setIsVisible(true)}
      >
        <Cluster
          space={1}
          wrap="nowrap"
        >
          {loading ? (
            <Skeleton
              width={18}
              height={18}
              circle
            />
          ) : (
            <SettingsIcon size="18" />
          )}
          <Text.Span size="small">
            {loading ? <Skeleton width={50} /> : t('settings')}
          </Text.Span>
        </Cluster>
      </Button>
      <Modal
        title={t('settings')}
        footer={(
          <Button
            data-testid="button-close-modal"
            onClick={() => setIsVisible(false)}
            hasBorder={false}
          >
            {t('close')}
          </Button>
        )}
        onCancel={() => setIsVisible(false)}
        visible={isVisible}
      >
        <Stack>
          <Stack space={1}>
            <Text.Paragraph>{t('settingsFontSize')}</Text.Paragraph>
            <Button.Group>
              {fontSizeOptions.map(option => (
                <Button
                  data-testid={`button-font-${option.name}`}
                  key={option.key}
                  size="large"
                  type={option.value === fontSize ? 'primary' : 'default'}
                  onClick={() => onChange('fontSize', option.value)}
                  block
                >
                  {option.name}
                </Button>
              ))}
            </Button.Group>
          </Stack>
          <Stack space={1}>
            <Text.Paragraph>{t('settingsAnimationSpeed')}</Text.Paragraph>
            <Button.Group>
              {animationSpeedOptions.map(option => (
                <Button
                  data-testid={`button-animation-${option.name}`}
                  key={option.key}
                  size="large"
                  type={option.value === animationSpeed ? 'primary' : 'default'}
                  onClick={() => onChange('animationSpeed', option.value)}
                  disabled={option.disabled}
                  block
                >
                  {option.name}
                </Button>
              ))}
            </Button.Group>
            {prefersReducedMotion && (
              <Text.Paragraph
                size="small"
                importance="low"
              >
                Animations are disabled based on your device preferences
              </Text.Paragraph>
            )}
          </Stack>
          <Stack space={1}>
            <Text.Paragraph>{t('darkMode')}</Text.Paragraph>
            <div>
              <DarkModeToggleContainer />
            </div>
          </Stack>
        </Stack>
      </Modal>
    </StyledWrapper>
  );
};

Settings.propTypes = {
  loading: PropTypes.bool,
  onTop: PropTypes.bool,
};

Settings.defaultProps = {
  loading: false,
  onTop: false,
};

export default Settings;
